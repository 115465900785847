import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../components/SEO';
import Header from '../../components/Header';
import ContentRow from '../../components/ContentRow';
import ImgW from '../../components/ImgW';


const headerColor = '#79d7f9';

const CasinoPage = ({ data }) => (
  <>
    <SEO
      title="Neosurf Casinos - Online Casinos That Accept Neosurf 2024"
      description="Find out the top-rated online casinos that accept Neosurf deposit method in 2024. List of the reputable Neosurf casinos 2024."
      alternateLanguagePages={[
        {
          path: 'casino',
          language: 'en'
        },
        {
          path: 'casino',
          language: 'fr'
        },
        {
          path: 'casino',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <Img fluid={data.neosurfHeader.childImageSharp.fluid} alt="Best Neosurf Casinos for 2024" />
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller">Top Online-Casinos, die Neosurf 2022 akzeptieren</h1>
            <p className="textColorDark">Beim Online-Glücksspiel mit echtem Geld gibt es verschiedene Zahlungsmethoden, darunter Debit- und Kreditkarten, E-Wallets und sogar Kryptowährungen. Eine der sichersten Optionen ist Neosurf, ein Gutschein-basiertes System, das Online-Zahlungen durch Eingabe eines 10-stelligen Codes ermöglicht. Bei der Nutzung von Neosurf sind keine sensiblen Daten erforderlich, und das Guthaben ist nicht mit einem Bankkonto verknüpft – ideal für sichere Online-Casino-Einzahlungen.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textUppercase">Beste Neosurf-Casinos</h2>
      <ImgW center fluid={data.neosurfVoucher.childImageSharp.fluid} alt="Screenshot of Neosurf Voucher" />
      <p className="textColorDark">Falls du dich entscheidest, Neosurf für Online-Zahlungen zu verwenden, kannst du es bei zahlreichen Online-Casinos einsetzen. Wir haben einige der besten Neosurf-Casinos ausgewählt:</p>
      <div className="grid c-2 bigGap">
        <div>
          <h3 className="textColorDark">LuckyStar Casino</h3>
          <p>Fühlst du dich glücklich? Wenn das Glück auf deiner Seite ist, gibt es keinen besseren Ort zum Spielen als das LuckyStar Casino. Es bietet eine große Sammlung spannender Casinospiele, von Video-Slots und progressiven Jackpots bis hin zu Tischspielen und Live-Casino-Spielen.</p>
          <p className="textAlignCenter"><a className="button" href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">TO LUCKYSTAR CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.lucky.childImageSharp.fluid} alt="LuckyStar – Our favorite Neosurf Casino" />
        </div>
        <div>
          <h3 className="textColorDark">AUSlots Casino</h3>
          <p>Auf der Suche nach großartigen und einzigartigen Slots? AUSlots bietet über 1.000 erstklassige Casinospiele, die auf PCs, Tablets und mobilen Geräten spielbar sind. Hier findest du unter den Zahlungsmethoden sowohl Neosurf als auch MyNeosurf. Melde dich an und erhalte heiße Willkommensboni und Cashback-Optionen mit nur 2x Umsatzanforderungen!</p>
          <p className="textAlignCenter"><a className="button" href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db">TO AUSlots CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.auslots.childImageSharp.fluid} alt="AUSlots Casino - awesome &amp; unique casino" />
        </div>
        <div>
          <h3 className="textColorDark">BetBTC Casino</h3>
          <p>Für Bitcoin-Fans ist BetBTC Casino die perfekte Wahl. Dieses neue Bitcoin-Casino bietet sofortige Einzahlungen und schnelle Auszahlungen, Hunderte hochwertiger Spiele, exzellenten Kundenservice und ein VIP-Programm.</p>
          <p className="textAlignCenter"><a className="button" href="https://www.betbtc.io/sign-up/a48acf12">TO BETBTC CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.betbtc.childImageSharp.fluid} alt="Perfect for Bitcoin fans – BetBTC Casino" />
        </div>
        <div>
          <h3 className="textColorDark">SpaceLilly Casino</h3>
          <p>Begleite SpaceLilly auf einer abenteuerlichen Reise durchs Universum, um die besten Casinospiele zu entdecken. Mit über 1.000 Spielen von Top-Anbietern und großzügigen Promotionen ist SpaceLilly perfekt für Casino-Fans.</p>
          <p className="textAlignCenter"><a className="button" href="http://www.spacelilly.com/?a_aid=5a6fb263dabe6&amp;a_bid=cd4655e5">TO SPACE LILLY</a></p>
        </div>
        <div>
          <Img fluid={data.spaceLilly.childImageSharp.fluid} alt="SpaceLilly Casino accepts Neosurf deposit method" />
        </div>
        <div>
          <h3 className="textColorDark">EmuCasino</h3>
          <p>EmuCasino, ein nutzerfreundliches australisches Online-Casino, bietet über 800 Spiele und spannende Casino-Rennen, bei denen du mehr aus deinen Pokies herausholen kannst.</p>
          <p className="textAlignCenter"><a className="button" href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db">TO EMU CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.emu.childImageSharp.fluid} alt="Australian online casino – EmuCasino" />
        </div>
        <div>
          <h3 className="textColorDark">777 Slots Bay Casino</h3>
          <p>777SlotsBay bietet mehr als 3.000 Casinospiele, darunter über 2.000 Slots und virtuelle Sportwetten. Egal ob erfahrener Spieler oder Neuling, es gibt fantastische Boni und preisgekrönten Kundensupport.</p>
          <p className="textAlignCenter"><a className="button" href="https://www.777slotsbay.com/?goft=355fce1f-1701-4bc8-b29d-e3e0ec292198">TO 777 SLOTS BAY CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.sevenSB.childImageSharp.fluid} alt="777 Slots Bay Casino" />
        </div>
        <div>
          <h3 className="textColorDark">777Bay Casino</h3>
          <p>777Bay Casino bietet über 3.000 Casinospiele von 47 Anbietern mit superschnellen Ladezeiten. Wenn dir das Glück nicht hold ist, könnten Cashback-Angebote von 777Bay dir helfen!</p>
          <p className="textAlignCenter"><a className="button" href="http://www.777bay.com/?a_aid=5a6fb263dabe6">TO 777BAY CASINO</a></p>
        </div>
        <div>
          <Img fluid={data.sevenB.childImageSharp.fluid} alt="777Bay Casino Logo" />
        </div>
      </div>
    </div>
    <ContentRow backgroundColor="#eee" padV>
      <h2 className="smaller textUppercase">Vorteile der Nutzung von Neosurf in Online-Casinos</h2>
      <p className="textColorDark">Neosurf erfordert keine Eingabe persönlicher oder bankbezogener Daten und ist daher eine sicherere Option für Online-Casino-Einzahlungen. Der Prozess ist zudem sehr einfach: Gutschein kaufen, Code eingeben und loslegen.</p>
      <ImgW center fluid={data.neosurfCity.childImageSharp.fluid} alt="Safe &amp; secure payment method" />
    </ContentRow>
    <div className="pageWidth">
      <h2 className="textColorDark">So funktioniert Neosurf</h2>
      <ul className="textColorDark">
        <li>Besuche die offizielle Neosurf-Website und finde den nächstgelegenen Händler</li>
        <li>Kaufe einen Neosurf-Gutschein in verschiedenen Beträgen</li>
        <li>Gib den Code auf der Website deines Online-Casinos ein</li>
      </ul>
      <p>Mit über 135.000 Verkaufsstellen weltweit und über 20.000 Akzeptanzstellen, darunter viele Spiele- und Unterhaltungsseiten, ist Neosurf einfach zugänglich und vielseitig einsetzbar.</p>
      <h2 className="textColorDark">FAQs</h2>
      <h3 className="textColorDark">Welche Händler bieten Neosurf-Gutscheine an?</h3>
      <p>Neosurf-Gutscheine sind an Standorten wie Supermärkten, Kiosken und Tankstellen erhältlich.</p>
      <h3 className="textColorDark">Kann ich mit Neosurf andere Zahlungsmethoden aufladen?</h3>
      <p>Ja, du kannst mit Neosurf-Gutscheinen E-Wallets und Prepaid-Karten aufladen.</p>
      <h3 className="textColorDark">Kann ich den Guthabenstand meines Gutscheins online prüfen?</h3>
      <p>Ja, besuche die Neosurf-Website, gehe zu „My Card“ und gib deinen Code ein.</p>
      <h3 className="textColorDark">Was ist MyNeosurf?</h3>
      <p>MyNeosurf ist ein E-Wallet, in dem alle Neosurf-Gutscheine sicher gespeichert werden können. Es kann auch mit Kreditkarten und Banküberweisungen aufgeladen werden.</p>
      <h3 className="textColorDark">Kann ich mit Neosurf Auszahlungen vornehmen?</h3>
      <p>Auszahlungen sind mit Neosurf nicht möglich. Nutze für Auszahlungen eine andere Zahlungsmethode.</p>
    </div>
  </>
)

export default CasinoPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    neosurfHeader: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Best-Neosurf-Casinos-for-2020.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    neosurfVoucher: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Screenshot-of-Neosurf-Voucher-2.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    neosurfCity: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Safe-secure-payment-method.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    lucky: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/LuckyStar-Our-favorite-Neosurf-Casino.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auslots: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/AUSlots-Casino-home.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Perfect-for-Bitcoin-fans-BetBTC-Casino.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    spaceLilly: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/SpaceLilly-Casino-accepts-Neosurf-deposit-method.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/Australian-online-casino-EmuCasino.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenSB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777slotsbay-accepts-Neosurf-deposit-method.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sevenB: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777bay-accepts-Neosurf-deposit-method.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 700,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
